const getGraphQLErrorCode = (error: any) => error.graphQLErrors?.[0]?.code;
const getGraphQLRawMessage = (error: any) => error.graphQLErrors?.[0]?.message;

/**
 * Get the best error string to display to the user.
 * errorCodesToDisplayStrings is any mapping of GraphQL error codes to display strings.
 * If we don't have any error strings defined, fall back to the GraphQL messages.
 * If there's some other non GraphQL error, return a generic message.
 */
export const getErrorToDisplay = (
  error: any,
  errorCodesToDisplayStrings?: Record<string, string>,
) => {
  const graphQLErrorCode = getGraphQLErrorCode(error);
  const graphQLErrorMessage = getGraphQLRawMessage(error);
  if (
    graphQLErrorCode &&
    errorCodesToDisplayStrings &&
    errorCodesToDisplayStrings[graphQLErrorCode]
  ) {
    return errorCodesToDisplayStrings[graphQLErrorCode];
  } else if (graphQLErrorMessage) {
    return graphQLErrorMessage;
  }
  return 'Something went wrong, please try again.';
};

import React, { useCallback } from 'react';

import styled from '@emotion/styled';
import AccountCircleSharp from '@material-ui/icons/AccountCircleSharp';
import { toast } from 'react-toastify';

import * as Fuel from '@convoy/fuel';
import { useTestDrive } from '@convoy/lynx.testdrive';

import { Router, Link, routes } from '~/routes';
import { useLogOutMutation } from '~/services/apollo';
import { flags } from '~/services/testdrive/featureFlags';
import { useViewer } from '~/services/viewer';
import { getErrorToDisplay } from '~/utils/errors';

const Navbar = styled(Fuel.Navbar)({
  borderBottom: `1px solid ${Fuel.Color.Light.N400}`,
  backgroundColor: Fuel.Color.White,
});

const LogoLink = styled.div({
  display: 'flex',
  cursor: 'pointer',
});

const Logo = styled(Fuel.Navbar.Logo.Icon)({
  color: Fuel.Color.Midnight,
});

const Title = styled.h1({
  fontSize: Fuel.Unit.LG,
  color: Fuel.Color.Midnight,
});

const strings = {
  appName: 'FleetCare',
  serviceableTrailers: 'Serviceable Trailers',
  fhwaScheduling: 'FHWA Scheduling',
  dashboard: 'Dashboard',
  mappings: 'Mappings',
  logOut: 'Log out',
};

interface Props {
  showMenuLinks?: boolean;
}

const Navigation = (props: Props) => {
  const { showMenuLinks } = props;

  const currentRoute = Router.asPath;

  const viewer = useViewer();
  const [logOutMutation] = useLogOutMutation({
    refetchQueries: ['Viewer'],
  });

  const [shouldShowFHWASchedulingTab, shouldShowDashboardTab] = useTestDrive([
    flags.showFHWASchedulingFlow,
    flags.showDashboardTab,
  ]);

  const handleLogOut = useCallback(async () => {
    try {
      const { errors } = await logOutMutation();
      if (errors) {
        const errorToDisplay = getErrorToDisplay(errors[0]);
        toast.error(errorToDisplay);
      } else {
        Router.push(routes.login);
      }
    } catch (error) {
      // Something else went wrong.
      const errorToDisplay = getErrorToDisplay(error);
      toast.error(errorToDisplay);
    }
  }, [logOutMutation]);

  if (!showMenuLinks) {
    return (
      <nav>
        <Navbar scheme='light'>
          <Fuel.Nav>
            <Logo />
            <Title>{strings.appName}</Title>
          </Fuel.Nav>
        </Navbar>
      </nav>
    );
  }

  return (
    <nav>
      <Navbar scheme='light'>
        <Fuel.Nav>
          <Link route={routes.serviceableTrailers}>
            <LogoLink>
              <Logo />
              <Title>{strings.appName}</Title>
            </LogoLink>
          </Link>
        </Fuel.Nav>
        <Fuel.Nav end='true'>
          <Fuel.Nav.Link
            Link={Link}
            route={routes.serviceableTrailers}
            active={currentRoute === routes.serviceableTrailers}
          >
            {strings.serviceableTrailers}
          </Fuel.Nav.Link>
          {shouldShowFHWASchedulingTab ? (
            <Fuel.Nav.Link
              Link={Link}
              route={routes.fhwaScheduling}
              active={currentRoute === routes.fhwaScheduling}
            >
              {strings.fhwaScheduling}
            </Fuel.Nav.Link>
          ) : (
            <></>
          )}

          {shouldShowDashboardTab ? (
            <Fuel.Nav.Link
              Link={Link}
              route={routes.dashboard}
              active={currentRoute === routes.dashboard}
            >
              {strings.dashboard}
            </Fuel.Nav.Link>
          ) : (
            <></>
          )}
          <Fuel.Nav.Link
            Link={Link}
            route={routes.mappings}
            active={currentRoute === routes.mappings}
          >
            {strings.mappings}
          </Fuel.Nav.Link>
          <Fuel.Nav.Dropdown icon={AccountCircleSharp} label={viewer!.name}>
            <Fuel.Nav.Link route='' onClick={handleLogOut}>
              {strings.logOut}
            </Fuel.Nav.Link>
          </Fuel.Nav.Dropdown>
        </Fuel.Nav>
      </Navbar>
    </nav>
  );
};

export default Navigation;

import { TestDriveFlags } from '@convoy/lynx.testdrive';

export const flags = {
  showFHWASchedulingFlow: 'FF_GOFI_FHWA_Scheduling',
  showDashboardTab: 'FF_GOFI_FCPortalDashboard',
  isUnderScheduledMaintenance: 'KS_GOFI_FC-Portal-Scheduled-Maintenance',
  usePagination: 'FF_GOFI_ServiceableTrailersPagination',
};

// Default variant values/trial data for local testing. Prevents us from having
// to start api-gateway-service locally and hit the prod TestDrive server.
export const devFlagOverrides: TestDriveFlags = {
  [flags.showFHWASchedulingFlow]: {
    'variant': 'on',
    'payload': JSON.stringify({}),
  },
  [flags.showDashboardTab]: {
    'variant': 'on',
    'payload': JSON.stringify({}),
  },
  [flags.isUnderScheduledMaintenance]: {
    'variant': 'off',
    'payload': JSON.stringify({}),
  },
  [flags.usePagination]: {
    'variant': 'on',
    'payload': JSON.stringify({}),
  },
};

import React, { useContext } from 'react';

import { gql } from '@apollo/client';

import { withContextQuery } from '@convoy/lynx.apollo';

import * as GraphQL from '~/services/apollo';

type Viewer = NonNullable<GraphQL.ViewerQuery['viewer']>;

const ViewerQuery = gql`
  query Viewer {
    viewer {
      id
      role
      name: realName
    }
  }
`;

export const ViewerContext = React.createContext<Viewer | null | undefined>(
  undefined,
);

export const useViewer = () => useContext(ViewerContext);

export const withViewer = withContextQuery<
  Viewer | null | undefined,
  GraphQL.ViewerQuery
>({
  name: 'withViewer',
  query: ViewerQuery,
  Context: ViewerContext,
  getValueFromResult: result => result.data && result.data.viewer,
});

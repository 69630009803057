import { createRouter } from '@convoy/lynx/router';

export const routes = {
  login: '/login',
  index: '/',
  dashboard: '/dashboard',
  fhwaScheduling: '/fhwa-scheduling',
  mappings: '/mappings',
  serviceableTrailers: '/serviceable-trailers',
};

const { Router, Link, getRequestHandler } = createRouter([
  {
    path: routes.login,
    page: 'login',
    name: 'Login',
  },
  {
    path: routes.index,
    page: 'index',
    name: 'Index',
  },
  {
    path: routes.dashboard,
    page: 'dashboard',
    name: 'Dashboard',
  },
  {
    path: routes.fhwaScheduling,
    page: 'fhwa-scheduling',
    name: 'FHWA Scheduling',
  },
  {
    path: routes.mappings,
    page: 'mappings',
    name: 'Mappings',
  },
  {
    path: routes.serviceableTrailers,
    page: 'serviceable-trailers',
    name: 'Serviceable Trailers',
  },
]);

export { Router, Link, getRequestHandler };

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import {
  TestDriveFlags,
  withTestDrive as withTestDriveBase,
} from '@convoy/lynx.testdrive';

import { getConfig } from '~/services/config';
import { useViewer } from '~/services/viewer';

import { devFlagOverrides } from './featureFlags';

const config = getConfig();

const doNothingQuery: DocumentNode = gql`
  query doNothingQuery {
    doNothing
  }
`;

const initialTestdriveFlags = {};

const customProcessResultCallback = (): TestDriveFlags => devFlagOverrides;

const withTestDriveDev = withTestDriveBase(
  config.testdrive,
  useViewer,
  initialTestdriveFlags,
  doNothingQuery,
  customProcessResultCallback,
);

const withTestDriveProd = withTestDriveBase(config.testdrive, useViewer);

export const withTestDrive = config.dev ? withTestDriveDev : withTestDriveProd;

import getNextConfig from 'next/config';

import { ApolloConfig } from '@convoy/lynx.apollo';

/**
 * Application configuration.
 */
export type Config = ServerConfig & ServiceConfig;

interface ServerConfig {
  stack: 'dev' | 'prod';
  dev: boolean;
  ssr: boolean;
  webPrefix?: string;
}

interface ServiceConfig {
  apollo: ApolloConfig;
  typekit?: {
    kitId: string;
  };
  apiUrl: string;
  testdrive: {
    projectId: string;
    refreshInSeconds: number;
    enabled: boolean;
  };
  pusher: {
    clientKey: string;
    cluster: string;
  };
  /** Whether MSW is enabled for mocking network requests */
  enableMsw: boolean;
}

/**
 * Return the application's configuration for the current environment.
 */
export const getConfig = (): Config => {
  const { publicRuntimeConfig, serverRuntimeConfig } = getNextConfig();
  return process.browser ? publicRuntimeConfig : serverRuntimeConfig;
};

/**
 * Return whether the application is in development.
 */
export const isDevelopment = () => getConfig().dev;

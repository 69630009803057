import { withApollo as withApolloBase } from '@convoy/lynx.apollo';
import { identity } from '@convoy/lynx/utils';

import { getConfig } from '~/services/config';

import { apolloConfig } from './apolloConfig';

const config = getConfig();
export const withApollo = !config.apollo
  ? identity
  : withApolloBase(apolloConfig);

import React from 'react';

import styled from '@emotion/styled';

import * as Fuel from '@convoy/fuel';

const CenteredContainer = styled.div({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  ...Fuel.Text.Size.MD,
});

const FullPageLoadingSpinner = () => (
  <CenteredContainer>
    <Fuel.Spinner size='XXL' />
  </CenteredContainer>
);

export default FullPageLoadingSpinner;

// Some components from @convoy/fuel are based upon react-dates,
// which requires global initialization and global CSS.
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import NextApp from 'next/app';

import { withInternalUser } from '@convoy/lynx.convoy';
import { compose } from '@convoy/lynx/utils';

import { withApollo } from '~/services/apollo';
import { withAuthenticatedLayout } from '~/services/authenticatedLayout';
import { getConfig } from '~/services/config';
import { withCsp } from '~/services/csp';
import { withFonts } from '~/services/fonts';
import { withPusher } from '~/services/pusher';
import { withQuery } from '~/services/query';
import { withSsr } from '~/services/ssr';
import { withTestDrive } from '~/services/testdrive/withTestDrive';
import { withViewer } from '~/services/viewer';
import { withFuel } from '~/services/withFuel';

import 'react-toastify/dist/ReactToastify.css';

const config = getConfig();

if (process.env.NODE_ENV === 'development' && config.enableMsw) {
  import('@convoy/lynx.msw/init').then(async ({ initMocks }) => {
    const { handlers } = await import('~/services/msw');
    initMocks(...handlers);
  });
}

export default compose(
  withCsp,
  withFonts,
  withFuel,
  withSsr,
  withQuery,
  withApollo,
  withInternalUser,
  withViewer,
  withPusher,
  withTestDrive,
  withAuthenticatedLayout,
)(NextApp);

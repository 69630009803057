import { withTypekit } from '@convoy/lynx/adobe';
import { compose, identity } from '@convoy/lynx/utils';

import { getConfig } from '~/services/config';

const config = getConfig();

export const withFonts = compose(
  !config.typekit ? identity : withTypekit(config.typekit),
);

import React, { useState, useEffect, useCallback } from 'react';

import { gql } from '@apollo/client';
import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';

import * as Fuel from '@convoy/fuel';
import { useTestDrive } from '@convoy/lynx.testdrive';
import { AppProps, AppComponent } from '@convoy/lynx/next';

import FullPageLoadingSpinner from '~/components/layouts/FullPageLoadingSpinner';
import Navigation from '~/components/layouts/Navigation';
import { Router, routes } from '~/routes';
import { useLogOutMutation, User_Role as UserRole } from '~/services/apollo';
import { useViewer } from '~/services/viewer';
import { getErrorToDisplay } from '~/utils/errors';

import { flags } from './testdrive/featureFlags';

const PageBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
});

const ContentBody = styled.main({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: `${Fuel.Unit.MD} ${Fuel.Unit.LG}`,
  minHeight: 0,
});

const CenteredContainer = styled.div({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  ...Fuel.Text.Size.MD,
});

const strings = {
  underMaintenance:
    'Fleet Care Portal is currently undergoing scheduled maintenance. We apologize for the inconvenience.',
  permissionDenied:
    'You do not have the correct permissions to view this page.',
  logOut: 'Log Out',
};

const allowedRoles = [UserRole.Ops, UserRole.OpsMaintenanceProvider];

export const withAuthenticatedLayout = (App: AppComponent): AppComponent => {
  const AuthenticatedAppLayout = (props: AppProps) => {
    const currentRoute = Router.asPath;

    const viewer = useViewer();
    const [logOutMutation, { loading }] = useLogOutMutation({
      refetchQueries: ['Viewer'],
    });
    const [isUnderScheduledMaintenance] = useTestDrive([
      flags.isUnderScheduledMaintenance,
    ]);

    const [error, setError] = useState<any>();

    const handleLogOut = useCallback(async () => {
      try {
        const { errors } = await logOutMutation();
        if (errors) {
          setError(errors[0]);
        } else {
          Router.push(routes.login);
        }
      } catch (error) {
        // Something else went wrong.
        setError(error);
      }
    }, [logOutMutation]);

    const getErrorMessage = useCallback(error => getErrorToDisplay(error), []);

    // If no user is logged in, redirect from content pages to login page
    useEffect(() => {
      if (currentRoute !== routes.login && viewer === null) {
        Router.push(routes.login);
      }
    }, [currentRoute, viewer]);

    // Always render the login page
    if (currentRoute === routes.login) {
      return (
        <>
          <header>
            <Navigation />
          </header>
          <main>
            <App {...props} />
          </main>
        </>
      );
    }

    // For content pages:
    // Render loader if viewer role is not populated yet
    if (viewer?.role === undefined || viewer?.role === null) {
      return <FullPageLoadingSpinner />;
    }

    // Prevent users from viewing pages if Fleet Care Portal is undergoing
    // scheduled maintenance
    if (isUnderScheduledMaintenance) {
      return <CenteredContainer>{strings.underMaintenance}</CenteredContainer>;
    }

    // Render the content page if viewer is authorized
    if (allowedRoles.includes(viewer.role)) {
      return (
        <PageBody>
          <header>
            <Navigation showMenuLinks />
          </header>
          <ContentBody data-el='AuthenticatedAppLayout-contentPage'>
            <App {...props} />
            <ToastContainer
              theme='dark'
              position='bottom-center'
              autoClose={false}
              closeOnClick={false}
              draggable
              style={{
                width: 'fit-content',
                maxWidth: '400px',
              }}
            />
          </ContentBody>
        </PageBody>
      );
    }

    // Render permission denied page if viewer not authorized/in all other cases
    return (
      <CenteredContainer data-el='AuthenticatedAppLayout-permissionDenied'>
        {strings.permissionDenied}
        <Fuel.Button
          css={{ marginTop: Fuel.Unit.MD }}
          onClick={handleLogOut}
          spinner={loading}
          data-el='AuthenticatedAppLayout-logOutButton'
        >
          {strings.logOut}
        </Fuel.Button>
        {error && getErrorMessage(error)}
      </CenteredContainer>
    );
  };

  AuthenticatedAppLayout.mutation = gql`
    mutation logOut {
      session {
        logout {
          viewer {
            id
          }
        }
      }
    }
  `;

  return AuthenticatedAppLayout;
};

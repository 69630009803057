import { TypePolicies } from '@apollo/client/cache/inmemory/policies';

import { ApolloOptions } from '@convoy/lynx.apollo';

import { getConfig, isDevelopment } from '../config';

import introspectionSchema from './types.generated';

const config = getConfig();

const typePolicies: TypePolicies = {
  TrailerYard: { keyFields: ['key'] },
  Trailer: {
    fields: {
      // activeServiceRequests keyArgs must be included here to prevent Apollo from storing each item in a separate cache
      activeServiceRequests: {
        keyArgs: ['input', ['filters', ['types']]],
      },
    },
  },
  Query: {
    fields: {
      trailersWithActiveServiceRequests: {
        keyArgs: ['input', ['filters', 'sorts', 'search']],
        merge(existing = { edges: [], pageInfo: {} }, incoming) {
          return {
            edges: [...existing.edges, ...incoming.edges],
            pageInfo: incoming.pageInfo || existing.pageInfo,
            __typename: 'TrailersConnection',
          };
        },
      },
    },
  },
};

export const getApolloConfigForTest: ApolloOptions = {
  ...config.apollo,
  possibleTypes: introspectionSchema.possibleTypes,
  cacheVersion: 3,
  typePolicies,
};

export const apolloConfig: ApolloOptions = {
  ...config.apollo,
  possibleTypes: introspectionSchema.possibleTypes,
  debug: isDevelopment(),
  cacheVersion: 3,
  typePolicies,
};
